body {
  font-size: 12px;
  font-family: 'Montserrat', 'Roboto', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

/*.ant-menu-item-selected,*/
/*.ant-menu-item-selected .anticon {*/
/*  color: #489543;*/
/*}*/

/*.ant-menu-item:active,*/
/*.ant-menu-submenu-title:active,*/
/*.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {*/
/*  background-color: #48954317;*/
/*}*/

/*.ant-btn-link:hover,*/
/*.ant-btn-link:focus {*/
/*  color: #489543;*/
/*}*/
