.ant-alert {
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.ant-alert-message {
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
